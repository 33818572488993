<template>
  <a-card id="AccountGroupInfluencer" :widget="true" :title="'Account Group'">
    <div v-if="chartLoading" class="load-wrapper">
      <a-skeleton :loading="chartLoading" active />
    </div>
    <span v-if="!chartLoading && selectedData.length > 0" class="influencer-total">
      <span class="total-title">Total:</span>
      <span class="total">{{ influencerCount + ' influencers' }}</span>
    </span>
    <div v-if="!chartLoading && selectedData.length > 0" class="chart-wrapper">
      <EChart
        :type="'pie'"
        :data="selectedData"
        :doughnut-chart="false"
        :chart-type="type"
        @chartClick="onChartClick"
      />
    </div>
    <div v-if="!chartLoading && selectedData.length <= 0" class="no-data-wrapper">
      <NoDataAspect :title="'No Data'"></NoDataAspect>
      <a-button size="large" type="default" class="add-button" :outlined="true" @click="addAccountGroup">
        + Add Account Group
      </a-button>
    </div>
  </a-card>
</template>

<script>
import { ref, toRefs, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import EChart from '@/components/Chart/Echart.vue';
import NoDataAspect from '@/components/Error/NoDataAspect.vue';
export default {
  name: 'AccountGroupInfluencer',
  components: {
    NoDataAspect,
    EChart,
  },
  props: {
    filter: Object,
    chartData: Array,
    loading: Boolean,
  },
  setup(props) {
    const store = useStore();
    const { filter, loading, chartData } = toRefs(props);
    const ownMediaData = computed(() => store.getters['config/ownMediaData']);
    const { dispatch } = useStore();
    const router = useRouter();
    const type = ref('account group');
    const totalCount = ref(0);
    const influencerCount = ref(0);

    let chartLoading = ref(loading.value);

    const selectedData = ref([]);

    const getInfluencerType = () => {
      let influencerType = null;

      if (chartData.value && chartData.value != null) {
        influencerCount.value = chartData.value.relatedInfluencerCount
        influencerType = chartData.value.chartData;
      }

      let lists = [];

      if (influencerType !== null) {
        let visibleDataTotal = 0;
        let visibleData = [];

        for (const influencer of influencerType) {
          visibleDataTotal += 1;
          visibleData.push({
            name: influencer.name,
            value: influencer.count,
            percent: influencer.percent.toString(),
          });
        }

        totalCount.value = visibleDataTotal;

        if (visibleData && visibleData.length) {
          for (let index in visibleData) {
            const color = getColor(false, index);
            lists.push({
              name: visibleData[index].name,
              value: visibleData[index].value,
              valueAsPercent: visibleData[index].percent,
              itemStyle: {
                color: color,
              },
            });
          }
        }
      }

      selectedData.value = lists;

      chartLoading.value = false;
    };

    const getColor = (same, index) => {
      const defaultColors = [
        '#2C99FF',
        '#FF87B7',
        '#4DD4AC',
        '#5F63F2',
        '#FFBD59',
        '#F44771',
        '#353E6C',
        '#7961AC',
        '#B9C0DE',
        '#D3D3D3',
      ];
      if (index <= 10) {
        return defaultColors[index];
      } else {
        let lastColor;
        var rgb = [];
        if (same && lastColor) {
          rgb = lastColor;
        } else {
          rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
          var mix = [rgb[0] / 50, rgb[1] / 50, rgb[2] / 50]; //51 => 255/5
          var mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(function (x) {
            return Math.round(x);
          });
          lastColor = rgb;
          return 'rgb(' + mixedrgb.join(',') + ')';
        }
      }
    };

    // Modal
    const onChartClick = (value) => {
      if (value) {
        const userId = [];
        const groupName = value.data.name;
        for (let media of ownMediaData.value) {
          if (media.name === groupName) {
            const medias = media.medias;
            for (let social of medias) {
              userId.push(social.userId);
            }
          }
        }
        const newFilter = JSON.parse(JSON.stringify(filter.value));
        let f = {
          payload: {
            title: `Messages from "${value.data.name}"`,
          },
          criteria: {
            ...newFilter,
            userId,
            sort: {
              direction: 'desc',
              type: 'engagement_score',
            },
            highlight: {
              enable: true,
            },
          },
          type: 'influencer',
        };

        dispatch('message/showMessageModal', f);
      }
    };

    const addAccountGroup = () => {
      router.push('/settings?activeKey=2');
    };

    // INIT part
    // const init = (filterValue) => {
    const init = async () => {
      await getInfluencerType();
    };

    watch(loading, () => {
      chartLoading.value = loading.value;
    });

    watch(
      () => filter.value,
      () => {
        init();
      }
    );

    watch(
      () => chartData.value,
      () => {
        init();
      }
    );

    return {
      chartLoading,
      selectedData,
      onChartClick,
      type,
      addAccountGroup,
      totalCount,
      influencerCount
    };
  },
};
</script>

<style lang="scss">
@import '../../../config/theme/colors.json';
#AccountGroupInfluencer {
  .mode-dropdown-mobile {
    width: 104px;
    height: 100px;
  }
  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;
    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }

  .industry-representation-selector-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 12px 0;
  }
  @media screen and (max-width: 560px) {
    .industry-representation-selector-wrapper {
      margin-bottom: 0;
      padding: 15px 0;
    }
  }
  .industry-accumulation-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    row-gap: 16px;
    column-gap: 16px;
    .industry-mode-toggle {
      .ant-dropdown-trigger {
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .loading-wrapper,
  .no-data-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .loading-wrapper {
    min-height: 350px;
  }

  .no-data-wrapper {
    min-height: 421px;
    .add-button {
      border-radius: 4px;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0 auto;
      background: transparent;
      color: #3371ff;
      border: 1px solid #3371ff;
    }
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
    display: block;
  }
  @media screen and (max-width: 560px) {
    .ant-card-head-wrapper .ant-card-extra {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      column-gap: 12px;
    }
  }
  .ant-dropdown {
    .ant-dropdown-menu {
      min-width: 200px;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0;
    }
  }

  .chart-type {
    width: fit-content;
    margin-left: auto;
    padding: 15px 0;
    line-height: 1.4;
  }

  @media screen and (max-width: 560px) {
    .chart-type {
      margin-left: 0;
      justify-content: flex-end;
    }
  }

  .more-menu {
    color: #9299b8;
  }

  .mode-mobile {
    width: 104px;
    height: 100px;
    box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.2);
    border-radius: 6px;
  }
  .influencer-total {
    font-size: 12px;
    line-height: 16px;
    .total-title {
      color: #9299b8;
      padding-right: 4px;
    }
    .total {
      color: #5a5f7d;
    }
  }
  .chart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    x-vue-echarts {
      display: flex !important;
      height: 400px !important;
    }
  }
}
</style>
